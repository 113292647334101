body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
}

.App {
    text-align: left;

}

.App-logo {
    alignment: left;
    vertical-align: middle;
    height: 80px;
    background-color: white;
}

.App-header {
    background-color: #fff;
    height: 150px;
    padding: 20px;
    color: black;
    text-align: center;
}

.App-title {
    font-size: 1.5em;
}

.App-input {
    padding: 20px;
}

.App-output {
    padding: 20px;
}

.output-label {
    text-align: right;
}

.header {
    font-size: 1.5em;
    text-align: center;
    padding: 20px;
}

.sub-header {
    font-size: 1.2em;
    text-align: center;
}

/*some css I added*/
input[type="file"] {
    display: inline-block;
}

textarea {
    white-space: nowrap;
    overflow: auto;
}

/*end of my css*/
@-webkit-keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
@keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

